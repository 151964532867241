import {URL_SERVICIOS}  from './UrlWebServis';

export function PostData(type, userData){    

    return new Promise((resolve, reject) => {
        fetch(URL_SERVICIOS+type,{
            method: 'POST',
            body: JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            console.log("Error " + error);
           userData.error_login =  true;
           reject(error);
           
        })
    })    
}