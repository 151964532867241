import {URL_SERVICIOS}  from './UrlWebServis';
import { Usuario } from './Usuario';

export function GetData(type){    
    const usuario = Usuario();

    let token = "";
    if (usuario)
        token = usuario.token;

    return new Promise((resolve, reject) => {        
        console.log(URL_SERVICIOS+type+'/'+token)
        fetch(URL_SERVICIOS+type+'/'+token)   
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
           reject(error);
        })
    })    
}