import React, { Component, useEffect, useState } from 'react';
import {GetData} from '../../../services/GetData';
import {Link} from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fade from '@material-ui/core/Fade';
import { LinearProgress } from '@material-ui/core';

const GradoMaterias = ({id_docente, id_grados, id_grupo}) => {

      const [lista_asignaturas, setAsignaturas] = useState([]);
      const [periodo, setPeriodo] = useState(null);
      const [loading, setLoading] = useState(false);


      useEffect(() => {
            const datosperiodo = () => {
                let p  = JSON.parse(sessionStorage.getItem('periodo'));
                setPeriodo(p);
                
                return true;
            }
            datosperiodo();
      }, [])

      useEffect(() => {
          
          if (periodo != null)
              listar_materias();
      }, [periodo]);
      
      
      const listar_materias = () => {

                let link_ind = "#";

              if (periodo.estado_periodo){
                  if (periodo.estado_periodo === "1")
                      link_ind = `/home/indicadores`;
                  else
                      link_ind = `/home/calificacionesresumen`; //en el caso de que el periodo cerro puede ver el resumen de la notas de esa asignatura    
              }
        
              if (periodo.nivelaciones === "1")
                  link_ind = `/home/nivelaciones`;

            if (!id_docente || !id_grados || !id_grupo)
                return; 
        
                setLoading(true);
        
                GetData('asignaciones/asignaturasdocente/' + id_docente + "/" + id_grados + "/" + id_grupo + "/" + periodo.nivelaciones, null).then ((result) =>{
                    let responseJSON = result;
        
                    if (responseJSON.error === false){
                        let lasigna = responseJSON.asignaturas.map(                         
                              item => <Link to={`${link_ind}/${id_grados}/${id_grupo}/${item.id_asignatura}`} key={item.id_asignatura} params={{ id_grado: id_grados, id_asignatura: item.id_asignatura }} style={{ textDecoration: 'none' }} href="/home/indicadores">                                
                              <ListItem button>                                  
                                          <ListItemText primary={item.nombre_asignatura} >
                                              
                                          </ListItemText>
                                      </ListItem></Link>);

                              setAsignaturas(lasigna);
                              setLoading(false);                        
                        
                    }else{
                      console.log("Error al cargar las aginaciones");
                    }
                })
                return true;
      } 

      return (  
              <>
              { loading &&
                    <LinearProgress />
               }
              <ul className="list-group list-group-flush">
                  
                      {lista_asignaturas}
                
              </ul>
              </>
      );
}
 
export default GradoMaterias;

/*

class GradoMaterias extends Component {

  constructor(props){
    
    let periodo = {
        id_periodo : 0,
        estado_periodo : "0",
        nivelaciones : "0",
        fecha_cierre: null,
        fecha_cierre_niv: null,

    }

    periodo  = JSON.parse(sessionStorage.getItem('periodo'));
   

    super(props);
        this.state = {
            id_docente: props.id_docente,
            id_grado: props.id_grados,
            id_grupo: props.id_grupo,
            lista_asignaturas: "",
            periodo: periodo,
            loading: false
        }
        this.listar_materias = this.listar_materias.bind(this);
  }

  listar_materias(){
  
      let link_ind = "#";

      
      if (this.state.periodo.estado_periodo){
          if (this.state.periodo.estado_periodo === "1")
              link_ind = `/home/indicadores`;
        }

      if (this.state.periodo.nivelaciones === "1")
          link_ind = `/home/nivelaciones`;

    if (!this.state.id_docente || !this.state.id_grado || !this.state.id_grupo)
       return; 

        this.setState({loading: true});

        GetData('asignaciones/asignaturasdocente/' + this.state.id_docente + "/" + this.state.id_grado + "/" + this.state.id_grupo + "/" + this.state.periodo.nivelaciones, this.state).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                let lasigna = responseJSON.asignaturas.map(                         
                      item => <Link to={`${link_ind}/${this.state.id_grado}/${this.state.id_grupo}/${item.id_asignatura}`} key={item.id_asignatura} params={{ id_grado: this.state.id_grado, id_asignatura: item.id_asignatura }} style={{ textDecoration: 'none' }} href="/home/indicadores">
                        <Fade in={this.state.loading} timeout={1000}>
                      <ListItem button>                                  
                                  <ListItemText primary={item.nombre_asignatura} >
                                      
                                  </ListItemText>
                              </ListItem></Fade></Link>);
                this.setState({lista_asignaturas: lasigna, loading: false});
                
            }else{
              console.log("Error al cargar las aginaciones");
            }
        })
  }    

  render() {
    
    let mensaje_loading = "";

    if(this.state.loading)
        mensaje_loading = "Espere....";

    return (
      
    );
  }

  componentDidMount() {
    this.setState({loading: true});  
    this.listar_materias();
    
  }  
  
}

export default GradoMaterias;
*/