import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {Usuario} from '../../services/Usuario';
import {GetData} from '../../services/GetData';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Avatar } from '@material-ui/core';
import {Link} from 'react-router-dom';



import ModalIndicador from './ModalIndicador';
class ModificarIndicadores extends Component {

 
    constructor(props){
      super(props);
      
      let usuariosession = Usuario();
      let id_usuario = null;
      let token = null;
      let id_docente = null;      

      if (usuariosession){
        id_usuario  = usuariosession.id_usuario;
        token = usuariosession.token;
        id_docente = usuariosession.id_docente;
    }

      this.state = {
            id_usuario: id_usuario,
            id_docente: id_docente,
            token: token,  
            id_asignatura: props.match.params.idAsignatura,
            id_grado: props.match.params.idGrado,
            id_grupo: props.match.params.idGrupo,
            num_periodo: 0,
            nombre_asing: '',
            lista_indicadores: <TableRow key={0}><TableCell align="center">Cargando ...</TableCell></TableRow>,
            indicadoredoc : null,
            openloading: false
      }
      this.info_grado = this.info_grado.bind(this);
      this.listar_indicadores = this.listar_indicadores.bind(this);
      this.info_asigantura = this.info_asigantura.bind(this);
      this.showloagin = this.showloagin.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.listar_indicadores_sub= this.listar_indicadores_sub.bind(this)
    }

    showloagin(estado){
        this.setState({
            openloading: estado
        })
    }   

    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    handleChange(e) {                       
        const indicadoredoc = this.state.indicadoredoc;
        indicadoredoc[e.target.name] = e.target.value;
        this.setState({ indicadoredoc });     
    }

    periodo_actual(){
      GetData('periodos/actual/', this.state).then ((result) =>{          
        let responseJSON = result;
        
        if (responseJSON.error === false){
          let periodo = responseJSON.periodo;  
        
          
        
          this.setState({num_periodo: periodo});
        }
      });
  }

    info_grado(){
    
        GetData('grados/infogrado/' + this.state.id_grado, this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
            
            let min_ganada = responseJSON.min_ganada;  
            let max_nota = responseJSON.max_nota;  
            let nom_grado = responseJSON.nom_grado;  
            let nivel_ac = responseJSON.nivel;
            this.setState({
                  min_ganada: min_ganada,
                  max_nota: max_nota,
                  nom_grado: nom_grado,
                  nivel_ac: nivel_ac
              }, () => {
                  this.listar_indicadores();
              });
          }
        });    
      }    

      info_asigantura(){
        let id_docente = this.state.id_docente;
    
        GetData('asignaturas/infoasignatura/' + this.state.id_asignatura + "/" + id_docente, this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
    
            let nombre_asing = responseJSON.asignatura;  
    
            this.setState({
                  nombre_asing: nombre_asing
              });
            
          }else{
              this.setState({errorvalidardoc: true})
              
          }
        }); 
        return true;  
        
      }

      listar_indicadores_sub(){
          this.listar_indicadores();
      }
      

      listar_indicadores(){
           
        const StyledTableCell = withStyles(theme => ({
          head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
          },
          body: {
            fontSize: 14,
          },
        }))(TableCell);
    
        const StyledTableRow = withStyles(theme => ({
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: theme.palette.background.default,
            },
          },
        }))(TableRow);

        GetData('indicadores/listaindicadoresgra2/' + this.state.id_grado + '/' + this.state.id_asignatura + '/' + this.state.id_docente, this.state).then ((result) =>{
              let responseJSON = result;
              if (responseJSON.error === false){
                  let listaindi = null;
                  
                       this.setState({
                            indicadoredoc: responseJSON.indicadores
                       }, () => {
                          listaindi = this.state.indicadoredoc.map(
                            (item, index) =>  <StyledTableRow hover  key={item.id_indicador} className="TextoTabla"> 
                                              <StyledTableCell  align="left">{item.codigo_indic}</StyledTableCell >
                                              <StyledTableCell  align="left">
                                                  {item.descripcion_indic} 
                                              </StyledTableCell >
                                              <StyledTableCell  align="center">
                                                  {item.id_periodo}                                 
                                              </StyledTableCell >
                                              <StyledTableCell  align="center">
                                                    <ModalIndicador key={index} id_indicador={item.id_indicador} 
                                                                    codigo_indic={item.codigo_indic} 
                                                                    descripcion_indic={item.descripcion_indic}
                                                                    id_periodo={item.id_periodo}
                                                                    id_asignatura={this.state.id_asignatura}
                                                                    id_grado={this.state.id_grado}
                                                                    num_periodo={this.state.num_periodo}
                                                                    listar_indicadores_sub={()=> this.listar_indicadores_sub()}
                                                                    cant_notas = {item.cant}
                                                                    />
                                              </StyledTableCell >
                                          </StyledTableRow >  
                            
                            
                            
                            
                                               
                       );
                      } 
                    );
                  
                  
                  this.setState({lista_indicadores: listaindi, loading: false});
                  
              }else{
                console.log("Error al cargar las aginaciones");
              }
          })
          return true;  
        }

    render() {

        if (!this.state.id_usuario){
            return (<Redirect to={'/login'} />)
          }    

          const StyledTableCell = withStyles(theme => ({
            head: {
              color: theme.palette.common.white,
            },
            body: {
              fontSize: 14,
            },
          }))(TableCell);

        return (
                <>
                 

                <div className="container" >
                        <br />

                       

                        <Grid container spacing={0} >
                            <Grid item xs={12}>
                            <Paper className="PaddingPaper"  >

                                <Avatar className="IconoPaper">
                                                <i className="material-icons ColorAzul">
                                                        assignment_turned_in
                                                </i>
                                </Avatar>  

                                <Typography variant="h5" component="h3" className="Titulo">
                                    Indicadores para ({this.state.nom_grado} - {this.state.id_grupo})  -  {this.state.nombre_asing} del año {this.state.anio_actual} 
                                </Typography>

                                <Typography variant="h6" component="h3" className="msginfo"><i className="material-icons colorInfo">
                                    info</i>De clic en el icono del lápiz para modificar el indicador deseado. Recuerde que no se puede utilizar indicadores de periodos pasados.
                                    Los indicadores del periodo actual que ya tienen notas ingresadas no se pueden modificar.
                                    </Typography>

                                        <Table className="MargenButton MargenSuperior" >
                                            <TableHead className="AltoFila">
                                                <TableRow  className="AltoFila"> 
                                                <StyledTableCell className="FilaEncabezadoTabla" width="5%" >Código</StyledTableCell>
                                                <StyledTableCell className="FilaEncabezadoTabla" width="85%" align="left">Concepto</StyledTableCell>
                                                <StyledTableCell className="FilaEncabezadoTabla" width="5%" align="left">Periodo</StyledTableCell>
                                                <StyledTableCell className="FilaEncabezadoTabla" width="5%" align="left">Acción</StyledTableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                
                                                    {this.state.lista_indicadores}
                                        
                                            </TableBody>
                                        </Table>
                                    

                                <Button type="button" variant="contained" className="MargenBoton" component={Link} to={"/home/indicadores/" + this.state.id_grado + "/"+ this.state.id_grupo + "/" + this.state.id_asignatura} color="primary"> Regresar </Button>
                                
                            </Paper>
                            </Grid>
                        </Grid>

                        

                    </div>

                </>

        );
    }

    componentDidMount() {
        this.setState({loading: true});  
        this.periodo_actual();
        this.info_asigantura();
        this.info_grado();
       
      }    

}


export default ModificarIndicadores;