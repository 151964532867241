import React, { Component } from 'react';
import './PaginaInactivo.css';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Avatar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

class PaginaInactivo extends Component {

  constructor(props){

    super(props);
    this.state = {

    }

  }


  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  render() {
    
  

    return (
      
      <div className="container" >
            
    
      <Grid container spacing={0} className="maximoancho" >
          <Grid item xs={12}>

              <Paper className="PaddingPaper"  >
                      <Avatar className="IconoPaper">
                                      <i className="material-icons ColorAzul">
                                      av_timer
                                      </i>
                      </Avatar>  
                      <Typography variant="h5" component="h3" className="Titulo">
                                    Cerrrado por inactividad
                      </Typography>
                      <Grid item xs={4} className="img_ancho">
                      <img className="imgtiempo" src="/images/hourglass.png" alt="tiempoagotado" /> 
                      </Grid>
                      <Typography variant="body1" component="h4" >
                      La plataforma se cierra automáticamente cuando se supera el tiempo de inactividad de 10 minutos, de clic en ingresa para iniciar nueva mente.
                      </Typography>
                      <hr />
                      <Button type="button" variant="contained" className="MargenBoton" component={Link} to={"/login/"}  color="primary"> Ingresar </Button>


                      </Paper>

            </Grid>

            </Grid>

    </div>
   

    );
  }
}

export default PaginaInactivo;
