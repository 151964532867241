import React, { Component } from 'react';
import Routes from './routes';
import './App.css';
import {GetData} from './services/GetData'

class App extends Component {

  verion_app(){
      
      const versionapp = 5;

      sessionStorage.setItem('versionapp',versionapp);
/*
      GetData('app/', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
            
         let num_version =  responseJSON.num_version;
         let fecha_version = responseJSON.fecha_version;

         if (versionapp !== parseInt(num_version)){
              this.clear_cache();
          }else{
              console.log("Actualizado versión...." + versionapp + " (" + fecha_version + ")");
          }
        }
      });   
*/
  }

  clear_cache(){
    //window.parent.caches.delete("call");
    window.location.reload();
  }

  render() {

   


    return (<>                    
              <div>
                        <Routes  />
 
              </div> 
          </>);
      
  }

  componentDidMount(){
    this.verion_app();
  }
}

export default App;
