import React from 'react';
import { Redirect } from "react-router-dom";
import { Usuario } from "./Usuario";

export function AuthNivelacion({ children }) {
  let usuario = Usuario();
  
  
  if (usuario.nivel_es === "0") {
        return (<Redirect to={'/home'} />)
  } else {
        return children;
  }
}
