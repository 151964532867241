import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import {PostData} from '../../services/PostData';
import LinearProgress from '@material-ui/core/LinearProgress';

class InputNotaFinalP extends Component {
    constructor(props){
        super(props);
        this.state = {
            index: props.index,
            notaalumno: props.nota,
            guardado: false,
            editactivo: false,
            error: false,
            urimg: '/images/save_exito.png'
        }
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.guardar_nota = this.guardar_nota.bind(this);
        this.resetSave = this.resetSave.bind(this);
            
    }

    func_round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }


    func_validarnota(nota){
        switch(nota){
            case 'A':
                return true;
            case 'D':
                return true;
            case 'E':
                return true;
            default:
                return false;

        }
    }

    handleKeyUp(e) {
        //console.log(e.key);
        
  
        if (e.key === "Enter"){
  
            let notaing = e.target.value;

            if (notaing === 'A' || notaing ===  'D' || notaing ===  'E'){
                this.setState({id_matricula: e.target.id});                    
                this.guardar_nota(notaing);  
            }else{
                 this.setState({guardado: false});
                 return;
            }
            
            
            
        }else{
             this.setState({guardado: false});
        }
    }

    keyPress(e){
        e.keyCode = 0;
     }    

    guardar_nota(notaing){    
        
        let datosenvio = {
            id_matricula : this.props.id_matricula,
            id_grado: this.props.id_grado,
            id_grupo: this.props.id_grupo,
            id_asignatura: this.props.id_asignatura,            
            nota_alumno: notaing
        } 
       
        if (!notaing){
            this.setState({error: true});
            return;
        }


        if (this.func_validarnota(notaing) === false){
            this.setState({guardado: false, error: true});
            return;
        }        

        this.setState({editactivo: true})

        PostData('notafinalperiodo/guadarnota/', datosenvio).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){              
              this.props.tab_control(this.props.id_matricula);       
              this.setState({guardado: true, error: false, editactivo: false});    

           //   return true;
          }else{
              
              this.setState({guardado: false, error: true, editactivo: false});
              console.log("Error al guardar la nota");
             // return false
          }
      })    
  }

  resetSave(e){
    
    console.log("Recibiendo el foco");  
    this.setState({guardado: false});
      
  }

  convertirnumero(numero){
        if (numero !== ""){
            return parseFloat(numero).toFixed(1)
        }
  }
  

    render() {

        return (
            <>
            <TextField error={this.state.error} 
                    inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' }
                    }}
                    innerRef={(el) => { this[`ref${this.props.index}`] = el; }} 
                    name={this.props.id_matricula} 
                    id={this.props.id_matricula} 
                    disabled={this.state.editactivo}
                    autoFocus={false}
                    className="quitarpading stiloimput" 
                    defaultValue={this.state.notaalumno}  
                    onBlur={this.resetSave}
                    onKeyDown={this.keyPress}
                    onKeyUp={this.handleKeyUp} 
                              onChange={this.props.onChangeNota}
                              onFocus={this.props.handleFocus}
                            />
                    <img src={this.state.urimg} alt="img"  style={{visibility: this.state.guardado ? 'visible' : 'hidden' }} />      
                    <LinearProgress  name={`prog_${this.props.index}`} style={{visibility: this.state.editactivo ? 'visible' : 'hidden' }} />         
            </>
        );
    }
    componentDidMount() {
      //  this.setState({open: false});  
  
      }  
}

export default InputNotaFinalP;