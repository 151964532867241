import React, { Component } from 'react';
import './CalificacionesPeriodo.css';
import Button from '@material-ui/core/Button';
import {GetData} from '../../services/GetData';
import {PostData} from '../../services/PostData';

import {Link} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert'
import LoadingScreen from 'react-loading-screen'


class CalificacionesPeriodo extends Component {
  



  constructor(props){
    super(props);
    this.state = {
        id_grado: props.match.params.idGrado,
        id_grupo: props.match.params.idGrupo,
        id_asignatura: props.match.params.idAsignatura,
        id_actividades: props.match.params.idActividad,
        id_indicador: props.match.params.idIndicador,
        nom_grado: null,
        lista_alumnos: null,
        titulos_tabla: null,
        titulos_tabla2: null,
        num_periodo: 0,
        nota_alumno: 0,
        id_matricula: 0,
        list_notas: {},
        guardado: {},
        listaindex : {},
        ConfPorcenNotas: [20, 80],
        procentaje_ptl: 0, //% plataforma
        calificacionPTF: false,
        errorvalidarExF: false,
        loading: true

    }
    this.listar_alumnos = this.listar_alumnos.bind(this);    
    this.periodo_actual = this.periodo_actual.bind(this);
    this.func_ingresarNota = this.func_ingresarNota.bind(this);
    this.onChangeNota = this.onChangeNota.bind(this);
    this.guardar_nota = this.guardar_nota.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.progreso_guardado = this.progreso_guardado.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.tab_control = this.tab_control.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.promedio = this.promedio.bind(this);
    this.procentaje_nota = this.procentaje_nota.bind(this);
    this.procentajeExa = this.procentajeExa.bind(this);
    this.info_grado = this.info_grado.bind(this)
    this.resumen_notas_nomal = this.resumen_notas_nomal.bind(this);
    this.func_acceso_plataforma = this.func_acceso_plataforma.bind(this);
    this.func_round = this.func_round.bind(this);
  }


  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  onChangeNota(e){
      const list_notas = this.state.list_notas;
      list_notas[e.target.name] = e.target.value;
      this.setState({ list_notas });      
  }  

  func_ingresarNota(id_matricula, nota){
      const list_notas = this.state.list_notas;
      list_notas[id_matricula] = nota
      this.setState({ list_notas });
      return true;      
  } 

  handleFocus(event) {
    event.target.select();
  }

  handleKeyUp(e) {
      //console.log(e.key);
      
      if (e.key === "Enter"){
          
          this.setState({id_matricula: e.target.id});
          this.guardar_nota(e.target.id);         
      }
  }

  updateValue(e) {

      const list_notas = this.state.list_notas;
      list_notas[e.target.name] = e.target.value;
      
      this.setState({ list_notas });
  }  

  progreso_guardado(id_matricula, estado){
            const guardado = this.state.guardado;
            guardado[`prog_${id_matricula}`] = estado;
            this.setState({ guardado });
  }

  tab_control(id_matricula){
      
      let listaindex = this.state.listaindex;

      Object.keys(listaindex).forEach(function(key) {
            let nums = parseInt(key) + 1;
        
              if (id_matricula === listaindex[key]){
                /** validar que no se pase el key  */
                let indice = listaindex[nums];

                document.getElementById(String(indice)).focus();
                return;
            }
       

                
      });
      return;
  }

  guardar_nota(id_matricula){    
        let notav = this.state.list_notas[id_matricula];
        let datosenvio = {
            id_matricula : id_matricula,
            id_actividades : this.state.id_actividades,
            nota_alumno: notav
        }
      
       
        PostData('calificaciones/guadarnota/', datosenvio).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){              
              
            //  this.progreso_guardado(id_matricula, false);
              this.tab_control(id_matricula);
              return true;
          }else{
              console.log("Error al guardar la nota");
              return false
          }
      })    
  }

  periodo_actual(){
      GetData('periodos/actual/', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
          let periodo = responseJSON.periodo;  
          this.setState({num_periodo: periodo});
        }
      });
     
  }

  info_grado(){
    
    GetData('grados/infogrado/' + this.state.id_grado, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){

        let nom_grado = responseJSON.nom_grado;  
        this.setState({
              nom_grado: nom_grado
          });
      }
    });    
  }  

  focusInput = (id) => this[`ref${id}`].current.focus();

  promedio(notas, columas){
      
    let suma = 0;
    let numnotas = 0;
    let indice = 0;
    let porc_not = 0;
    let PorcentaConf = this.state.ConfPorcenNotas;

    // console.log("Promedio.....");
    // console.log(notas['nombres_apellidos_al']);
   

    Object.keys(notas).forEach(function(key) {
      

        if (indice > 1){
          if (notas[key] !== null && key !== 'EXF' ){
              let nota = parseFloat(notas[key]);
              let exf =  parseFloat(notas['EXF']);
              let nota1 = (exf * (PorcentaConf[0] / 100));
              let nota2 = (nota * (PorcentaConf[1] / 100));

              porc_not = parseFloat(nota1) + parseFloat(nota2);
              suma += porc_not;
              
              numnotas++;
          }    
        }
      
      indice++;
      
    });
    let promedioN = 0;
    if (suma > 0)
       promedioN = (suma / numnotas);

    
    promedioN = this.func_round(promedioN,1);
    
    return parseFloat(promedioN).toFixed(1);
    
}  

  promedio_pft(notas, columas){
      
      let suma = 0;
      let numnotas = 0;
      let indice = 0;
      let porc_not = 0;
      let PorcentaConf = this.state.ConfPorcenNotas;
      //console.log("---------------------------------------");
      //console.log(PorcentaConf[0] + ", " + PorcentaConf[1] + ", " + PorcentaConf[2]); 

      
      // console.log("Promedio.....");
      // console.log(notas['nombres_apellidos_al']);
      Object.keys(notas).forEach(function(key) {
          
          if (indice > 1){
            if (notas[key] !== null && key !== 'EXF' && key !== 'PTL' ){
                let nota = parseFloat(notas[key]);
                let exf =  parseFloat(notas['EXF']);
                let npt =  parseFloat(notas['PTL']);
                let nota1 = (exf * (PorcentaConf[0] / 100)); //examen
                let nota2 = (npt * (PorcentaConf[1] / 100)); //plataforma
                let nota3 = (nota * (PorcentaConf[2] / 100)); //promedio actividade
                
                /*nota1 = this.func_round(nota1,1);
                nota2 = this.func_round(nota2,1);
                nota3 = this.func_round(nota3,1);*/

                porc_not = parseFloat(nota1) + parseFloat(nota2) + parseFloat(nota3);
                //let prom_d = this.func_round(porc_not,1);

                //console.log(notas['nombres_apellidos_al'] + " - " + nota1 + " " + nota2 + " " + nota3 + " " + prom_d);

                suma += porc_not;
                
                numnotas++;
            }    
          }
        
        indice++;
        
      });

      let promedioN = 0;
      if (suma > 0)
         promedioN = (suma / numnotas);
         
      promedioN = this.func_round(promedioN,1); 

      return parseFloat(promedioN).toFixed(1);;
      
  }

  func_acceso_plataforma(){
    GetData('calificacionesplataforma/habilitadoplaforma/' + this.state.id_grado + "/" + this.state.id_asignatura, this.state).then ((result) =>{
      let responseJSON = result;
      
      if (responseJSON.error === false){
          if (responseJSON.habilitado){
              this.setState({calificacionPTF: true, procentaje_ptl: responseJSON.porcentaje}, () =>{
                this.listar_alumnos(); 
              });
              return true;
          }else
              this.listar_alumnos(); 
      }else{
          return false;
      }

    });
  }  

  procentaje_nota(datos, cols){
    let retorno = [];
   
    Object.keys(cols).forEach(function(key) {
        let caption = cols[key].name

        switch (key){
          case '0':
              retorno.push(<td key={`td2${key}`}>{datos[caption]}</td>)
              break;
          case '1':    
              retorno.push(<td key={`td2${key}`}>{datos[caption]}</td>)
              break;
          default:              
                      switch (caption){
                          case "EXF":
                            let notaE = 0;                              
                            if (datos[caption] !== null)
                            notaE = datos[caption];

                               retorno.push(<td key={`td2${key}`} className="text-center">{parseFloat(notaE).toFixed(1)}</td>);
                              break;
                          case "PTL":
                              let notaP = 0;                              
                              if (datos[caption] !== null)
                                  notaP = datos[caption];
                                                            
                                retorno.push(<td key={`td2${key}`} className="text-center">{parseFloat(notaP).toFixed(1)}</td>);
                                break;                              
                          default:

                            let notap = 0;
                            let nexam = 0;                            

                            if (datos[caption] !== null)
                                notap = datos[caption];

                            if (datos['EXF'] !== null)
                                nexam = datos['EXF'];                                              

                                let promedio = (parseFloat(notap) * 0.8) + (parseFloat(nexam) * 0.2);
                              
                              let multiplier = Math.pow(10, 1 || 0);
                              promedio = Math.round(promedio * multiplier) / multiplier;
                              retorno.push(<><td key={`td2${key}`} className="sprom text-center">{parseFloat(notap).toFixed(1)}</td>
                                              <td className="text-center">{parseFloat(notap * 0.8).toFixed(1)}</td>
                                              <td className="text-center">{parseFloat(nexam * 0.2).toFixed(1)}</td>
                                              <td className="promfin text-center">{parseFloat(promedio).toFixed(1)}</td></>);
                      }
                         
            
        }
    });
    return retorno;
  }

func_round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

 procentaje_nota_pft(datos, cols){
    let retorno = [];
    let porc_plf = this.state.procentaje_ptl / 100;

    Object.keys(cols).forEach(function(key) {
        let caption = cols[key].name

        
        switch (key){
          case '0':
              retorno.push(<td key={`td2${key}`}>{datos[caption]}</td>)
              break;
          case '1':    
              retorno.push(<td key={`td2${key}`}>{datos[caption]}</td>)
              break;
          default:              
                      switch (caption){
                          case "EXF":
                              //let prom = func_round(datos[caption],1);
                              let nota = 0;                              
                              if (datos[caption] !== null)
                                  nota = datos[caption];

                              retorno.push(<td key={`td2${key}`} className="text-center">{parseFloat(nota).toFixed(1)}</td>);
                              break;
                          case "PTL":
                              let nota2 = 0;                              
                              if (datos[caption] !== null)
                                  nota2 = datos[caption];   

                                retorno.push(<td key={`td2${key}`} className="text-center">{parseFloat(nota2).toFixed(1)}</td>);
                                break;                              
                          default:
                              
                              let notap = 0;
                              let nexam = 0;
                              let nptl = 0;

                              if (datos[caption] !== null)
                                  notap = datos[caption];

                              if (datos['EXF'] !== null)
                                  nexam = datos['EXF'];               
                                  
                              if (datos['PTL'] !== null)
                                  nptl = datos['PTL'];                                                 

                              let promedio = (parseFloat(notap) * 0.6) + (parseFloat(nexam) * 0.2) + (parseFloat(nptl) * porc_plf)
                              
                                
                              promedio = this.func_round(promedio,1);

                              retorno.push(<><td key={`td2${key}`} className="sprom text-center">{parseFloat(notap).toFixed(1)}</td>
                                              <td className="text-center">{(parseFloat(notap) * 0.6).toFixed(1)}</td>
                                              <td className="text-center">{(parseFloat(nexam) * 0.2).toFixed(1)}</td>
                                              <td className="text-center">{(parseFloat(nptl) * porc_plf).toFixed(1)}</td>
                                              <td className="promfin text-center">{parseFloat(promedio).toFixed(1)}</td></>);
                      }
                         
            
        }
    }.bind(this));


      return retorno;
 }

 procentajeExa(nota){
    let def = 0;
    def = parseFloat(nota) * 0.2;
    return parseFloat(def).toFixed(1);
} 

//no incluye la calificacion por plataforma
resumen_notas_nomal(responseJSON){
      // se contruye las columnas de con los nombres de los campos para poder armar la tabla


      let porcenT = ["Prom", "80%", "20%", "100%"];
              
      if (!responseJSON.alumnos[0].EXF){
          this.setState({errorvalidarExF: true});
          return;
      }

      let objetos = Object.keys(responseJSON.alumnos[0]);
      let columas = [];
      let columasHeader2 = [];
     
      let sum_camp_nota = 100 - (13 - 8 - ((objetos.length - 4) * 32));
      let cont_colum = 0;
      let colt_columtotal = 0;
      Object.keys(objetos).forEach(function(key) {
          let nombrecampo = "";
          let anchocol = "";
          switch (key){
            case '0':
                nombrecampo = "Código";
                anchocol = "5%";
                break;
            case '1':
                nombrecampo = "Nombre del Alumno";
                anchocol = sum_camp_nota + "%";
                break;
            case '2':
                nombrecampo = objetos[key];
                anchocol = "8%";
                cont_colum++;
                break;                        
            default:
                nombrecampo = objetos[key];
                anchocol = "32%";
                cont_colum++;
                break;
          }
          colt_columtotal++;

          let campos = { header: nombrecampo, name: objetos[key], size: anchocol };
          columas.push(campos);
          
          

      });
      
      cont_colum = (colt_columtotal + (cont_colum - 1) * 4) - (cont_colum-1);

      let xy = 0;
      for(let x = 0; x<cont_colum; x++){
          if (x > 2 && x < (cont_colum) ){
             
            let campos = { header: porcenT[xy], name: porcenT[xy], size: "8%" };
            xy++;
            if (xy>3)
                xy = 0;

            columasHeader2.push(campos);  
          }
      }
                       
      const renderData = (data, cols) =>
          data.map((row, key) =><tr key={`tr1${key}`}>{this.procentaje_nota(row, cols)}<td key={`td1${key}`}>{this.promedio(data[key], columas)}</td></tr>);              



      let listaindi = renderData(responseJSON.alumnos, columas);

      let campos = { header: "Prom", name: "promedio", size: "8%" };
      columas.push(campos);                   

      let titulostabla = columas.map(
        (item, key) =><th key={key} className={key > 2 && key < colt_columtotal ? 'text-center': null} rowSpan={key <= 2 || key > (colt_columtotal - 1) ? '2': null} colSpan={key > 2 && key < colt_columtotal ? '4': '0'} width={item.size}>{item.header}</th>);

      let titulostabla2 = columasHeader2.map(
          (item, key) =><th key={`t2${key}`}  width={item.size}>{item.header}</th>);                

      this.setState({ lista_alumnos : listaindi, titulostabla: titulostabla, titulos_tabla2: titulostabla2 })
}

resumen_notas_pft(responseJSON){
        let porc_plf = this.state.procentaje_ptl;
        let porcenT = ["Prom", "60%", porc_plf + "%", "20%", "100%"];
                    
        if (!responseJSON.alumnos[0].EXF){
            this.setState({errorvalidarExF: true});
            return;
        }

        let objetos = Object.keys(responseJSON.alumnos[0]);
        let columas = [];
        let columasHeader2 = [];
      
        let sum_camp_nota = 100 - (13 - 8 - ((objetos.length - 4) * 32));
        let cont_colum = 0;
        let colt_columtotal = 0;
        Object.keys(objetos).forEach(function(key) {
            let nombrecampo = "";
            let anchocol = "";
            switch (key){
              case '0':
                  nombrecampo = "Código";
                  anchocol = "5%";
                  break;
              case '1':
                  nombrecampo = "Nombre del Alumno";
                  anchocol = sum_camp_nota + "%";
                  break;
              case '2':
                  nombrecampo = objetos[key];
                  anchocol = "8%";
                  cont_colum++;
                  break;                        
              default:
                  nombrecampo = objetos[key];
                  anchocol = "32%";
                  cont_colum++;
                  break;
            }
            colt_columtotal++;

            let campos = { header: nombrecampo, name: objetos[key], size: anchocol };
            columas.push(campos);
              

        });
        
        cont_colum = (colt_columtotal + (cont_colum - 2) * 5) - (cont_colum- 2);
        
        let xy = 0;
        for(let x = 0; x<cont_colum; x++){
            if (x > 3 && x < (cont_colum) ){
              
              let campos = { header: porcenT[xy], name: porcenT[xy], size: "8%" };
              xy++;
              if (xy>4)
                  xy = 0;

              columasHeader2.push(campos);  
              
            }
        }
       
        

        const renderData = (data, cols) =>
            data.map((row, key) =><tr key={`tr1${key}`}>{this.procentaje_nota_pft(row, cols)}<td key={`td1${key}`}>{this.promedio_pft(data[key], columas)}</td></tr>);              

        
        let listaindi = renderData(responseJSON.alumnos, columas);
        

        let campos = { header: "Prom", name: "promedio", size: "8%" };
        columas.push(campos);                   

        let titulostabla = columas.map(
          (item, key) =><th key={key} className={key > 3 && key < colt_columtotal ? 'text-center': null} rowSpan={key <= 3 || key > (colt_columtotal - 1) ? '2': null} colSpan={key > 3 && key < colt_columtotal ? '5': '0'} width={item.size}>{item.header}</th>);

        let titulostabla2 = columasHeader2.map(
            (item, key) =><th key={`t2${key}`}  width={item.size}>{item.header}</th>);                

        this.setState({ lista_alumnos : listaindi, titulostabla: titulostabla, titulos_tabla2: titulostabla2 });

}

listar_alumnos(){
    
    GetData('calificaciones/califiperiodo/' + this.state.id_grado + "/" + this.state.id_grupo + "/" + this.state.id_asignatura, this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
             
            if (!this.state.calificacionPTF)
                 this.resumen_notas_nomal(responseJSON);
            else{
                this.setState({ConfPorcenNotas: [20, this.state.procentaje_ptl, 60]}, () => {
                  this.resumen_notas_pft(responseJSON);
                });              
               
            }
                      
            this.setState({loading: false})
             
          }else{
            console.log("Error al cargar las calificaciones");
          }
      })
    }

  render() {
   
    
    let mensajeerror = "";
    if (this.state.errorvalidarExF === true){
          mensajeerror = <Alert key={1} variant="danger">
            <Alert.Heading>Error! </Alert.Heading>
            No se pueden mostrar las notas hasta que ingrese las notas del examen final 
      </Alert>
    }

    let mensajeinformativo = <div className="siglasNotas">
                                    <i className="material-icons coloramarillo">info</i>
                                    <strong> EXF:</strong> Calificación Examen Final  
                                    <strong> PTL:</strong> Calificación Plataforma 
                                    <strong> PROM:</strong> Promedio de las actividades</div>

    if (!this.state.calificacionPTF){
        mensajeinformativo = <div className="siglasNotas"><i className="material-icons coloramarillo">info</i><strong>EXF:</strong> Calificación Examen Final </div> 
    }
    return (
    
      
    <div className="container" >

      <LoadingScreen
                    loading={this.state.loading}
                    bgColor='#f1f1f1'
                    spinnerColor='#9ee5f8'
                    textColor='#676767'
                    logoSrc='/images/LogoCnotasGr.png'
                    text='...'
                  > 

        <div className="card" >
          <div className="card-header">
                <Button type="button" variant="outlined" component={Link} to={`/home/indicadores/${this.state.id_grado}/${this.state.id_grupo}/${this.state.id_asignatura}`} color="primary"> Regresar </Button>                
                <span className="h6 font_plataform fontRoboto LeftMargin">Calificaciones del 
                <strong>Período:</strong> {this.state.num_periodo}, 
                <strong>Grado: </strong>{this.state.nom_grado} - {this.state.id_grupo} </span>
          </div>
          <div className="card-body">
            
            {mensajeinformativo}  

            <h6 className="card-title">Lista de Alumnos</h6>     
            
            {mensajeerror}
            <table className="table table-bordered table-hover table-sm tablcal tblcalificaciones">
                <thead>
                <tr key={`trh${1}`} className="table-primary">{this.state.titulostabla}</tr>
                <tr key={`trh${2}`}>{this.state.titulos_tabla2}</tr>
                </thead>
                <tbody>{this.state.lista_alumnos}</tbody>
            </table>

            <Button type="button" variant="contained" component={Link} to={`/home/indicadores/${this.state.id_grado}/${this.state.id_grupo}/${this.state.id_asignatura}`} color="primary"> Regresar </Button>

          </div>
        </div>

        </LoadingScreen>

    </div>
   

    );
  }

  componentDidUpdate(prevProps, prevState) {
       // this[`ref${5}`].handleFocus();
  }
  componentDidMount() {      
    this.periodo_actual();
    this.info_grado();
    this.func_acceso_plataforma();   
  }    
}

export default CalificacionesPeriodo;
