import React, { Component } from 'react';
import './NotFound.css';


class NotFound extends Component {

  constructor(props){
    

  }


  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  render() {
    
  

    return (
      
    <div className="container">
          <h1>Not Found</h1>
    </div>
   

    );
  }
}

export default NotFound;
