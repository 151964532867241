import React, { Component } from 'react';
import './Actividades.css';
import {GetData} from '../../services/GetData';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import NuevaActividad from '../NuevaActividad/NuevaActividad'
import Moment from 'moment';

import { Avatar } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {Usuario} from '../../services/Usuario';

class Actividades extends Component {

  constructor(props){
    super(props);
    this.state = {
        usuario_session: Usuario(),
        id_asignatura: props.match.params.idAsignatura,
        id_grado: props.match.params.idGrado,      
        id_grupo: props.match.params.idGrupo,
        id_indicador: props.match.params.IdIndicador,
        lista_indicadores: <tr><td></td></tr>,
        cod_indicador: "",
        descripcion_indic: "",
        num_periodo:  0,
        periodo_actual: 0,
        fecha_cierre: null,
        periodo_abierto: true,
        vernotas: false
    }

    this.listar_indicadores = this.listar_indicadores.bind(this);
    this.periodo_actual = this.periodo_actual.bind(this);
    this.indicador_actual = this.indicador_actual.bind(this);
  }


  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  indicador_actual(){
    GetData('indicadores/buscarid/'+this.state.id_indicador, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){
        let indicador = responseJSON.indicador;  
        this.setState({
            id_asignatura : indicador.id_asigantura,
            id_grado : indicador.cod_grados,
            cod_indicador: indicador.codigo_indic,
            desc_indicador: indicador.descripcion_indic,
          });
      }
    });    
  }

  periodo_actual(){
      GetData('periodos/actual/', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){          
          let fecha_cierre = responseJSON.fecha_cierre;
          let periodo = responseJSON.periodo;
          this.setState({periodo_actual: periodo, num_periodo: periodo, fecha_cierre: fecha_cierre});
       
         }
      });
  }

  formato_fecha(fecha){
    if (fecha !== "0000-00-00")
       return Moment(fecha).format('DD/MMM/YYYY');
    else
       return "Sin Fecha";    
  }

  func_borrar_actividad(id_actividades){
      let res = window.confirm("¿Esta seguro de borrar la actividad?");
      if (res){
        GetData('actividades/delete/' + id_actividades + '/' + this.state.usuario_session.token, this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
               this.listar_indicadores();
               return;
          }
        });
      }
  }

  func_boton_borrar_actividad(item){
      if (item.nnotas === "0"){
          let numeral = "#";
          return <Link to={numeral} onClick={() => this.func_borrar_actividad(item.id_actividades)}>
              <Tooltip title="Borrar Actividad" placement="left-start">
                    <i className="material-icons ColorFuxia" >delete</i>
              </Tooltip></Link>
         
      }
  }

  listar_indicadores(){

    const StyledTableCell = withStyles(theme => ({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);

    const StyledTableRow = withStyles(theme => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.background.default,
        },
      },
    }))(TableRow);        

    GetData('actividades/lista/' + this.state.id_indicador + '/' + this.state.id_grado + '/' + this.state.id_grupo + '/' + this.state.num_periodo, this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){          
            
              let listaindi = responseJSON.actividades.map(
                    item =><StyledTableRow hover  key={item.id_actividades} className="TextoTabla"> 
                    <StyledTableCell  align="left">{this.formato_fecha(item.fechahora_act)}</StyledTableCell >
                    <StyledTableCell  align="left">{item.nombre_actividad}</StyledTableCell >
                    <StyledTableCell  align="center">
                          <NuevaActividad listarindicador={this.listar_indicadores.bind(this)} id_indicador={this.state.id_indicador} id_actividades={item.id_actividades} nombre_actividad={item.nombre_actividad} fechahora_act={item.fechahora_act} accion="edit"  /> 
                          <Link style={{display: this.state.periodo_abierto ? '' : 'none' }} to={`/home/calificaciones/${this.state.id_indicador}/${item.id_actividades}/${this.state.id_grado}/${this.state.id_grupo}/${this.state.id_asignatura}`}  title="Ingresar Notas">
                          <Tooltip title="Ingresar Notas" placement="left-start">
                                <i className="material-icons colorAzul" >assignment_turned_in</i>                                
                          </Tooltip>
                          </Link>   

                          {this.func_boton_borrar_actividad(item)}                     
                    </StyledTableCell >
                </StyledTableRow >);

              let vernotas = 0;  
              if (Object.keys(responseJSON.actividades).length > 0) {
                   vernotas = true;
              }
                  
              this.setState({lista_indicadores: listaindi, vernotas: vernotas});
              
          }else{
            console.log("Error al cargar las aginaciones");
          }
      })
    }

  render() {
    
    const StyledTableCell = withStyles(theme => ({
      head: {
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);   
    
    let BotonVerNotas = "";
    if (this.state.vernotas)
         BotonVerNotas = <Button type="button" variant="contained" className="MargenBoton" component={Link} to={"/home/calificacionesindicador/" + this.state.id_indicador + "/" + this.state.id_grado + "/"+ this.state.id_grupo + "/"+ this.state.id_asignatura + "/" + this.state.num_periodo} color="secondary"> Ver Notas </Button>;
    
    return (
      
    <div className="container" >
        

        <Grid container spacing={0} >
            <Grid item xs={12}>
              <Paper className="PaddingPaper"  >

                  <Avatar className="IconoPaper">
                                  <i className="material-icons ColorAzul">
                                      check_circle_outline
                                  </i>
                  </Avatar>  

                  <Typography variant="h5" component="h3" className="Titulo">
                         Actividades del Indicador {this.state.cod_indicador} 
                  </Typography>
                  
                        <h6 className="card-title">Lista de actividades del Indicador:</h6>     
                          <p>{this.state.cod_indicador} - {this.state.desc_indicador} - Período: {this.state.num_periodo}</p>
                   
                        <Table className="MargenButton MargenSuperior" >
                              <TableHead className="AltoFila">
                                <TableRow  className="AltoFila"> 
                                  <StyledTableCell className="FilaEncabezadoTabla" width="10%" >Fecha</StyledTableCell>
                                  <StyledTableCell className="FilaEncabezadoTabla" width="75%" align="left">Nombre actividad</StyledTableCell>
                                  <StyledTableCell className="FilaEncabezadoTabla" width="15%" align="left">Acción</StyledTableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                
                                      {this.state.lista_indicadores}
                                    
                              </TableBody>
                          </Table>
                     

                  <Button type="button" variant="contained" className="MargenBoton" component={Link} to={"/home/indicadores/" + this.state.id_grado + "/"+ this.state.id_grupo + "/" + this.state.id_asignatura}  color="primary"> Regresar </Button>
                  {BotonVerNotas}
                  <NuevaActividad estadoperiodo={this.state.periodo_abierto} idGrado={this.state.id_grado} idGrupo={this.state.id_grupo} listarindicador={this.listar_indicadores.bind(this)} id_indicador={this.state.id_indicador} id_actividades="" nombre_actividad="" fechahora_act="" accion="new"  />
              </Paper>
            </Grid>
        </Grid>


    </div>
   

    );
  }
  componentDidMount() {
    this.listar_indicadores();
    this.periodo_actual();
    this.indicador_actual();
  }    
}

export default Actividades;
