import React, { Component, lazy, Suspense } from 'react';
import './Grados.css';
import {GetData} from '../../../services/GetData';
//import GradoMaterias from '../GradoMaterias/GradoMaterias';
import {Usuario} from '../../../services/Usuario';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fade from '@material-ui/core/Fade';

import LoadingScreen from 'react-loading-screen'
import BTNObservador from '../BTNObservador/BTNObservador';
import BTNConsolidado from './BTNConsolidado';
import { Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import BallotIcon from '@material-ui/icons/Ballot';

import {Link} from 'react-router-dom';



const GradoMaterias = lazy(() => import("../GradoMaterias/GradoMaterias"));

class Grados extends Component {

  constructor(props){
    super(props);
    
    this.state = {
        usuario_session :  Usuario(),
        lista_grados: <div></div>,
        id_periodo: props.id_periodo,
        loading: true,
        loadingfade: false
    }
    this.listar_grados = this.listar_grados.bind(this);
  }

  boton_obervador(dirg){
      
      if (dirg !== 0){
          return <List component="nav"><ListItem button> <ListItemText primary="Obervador del Alumno" ></ListItemText></ListItem></List>
      }
  }



  listar_grados(){
   
    if (!this.state.usuario_session.id_docente)
       return; 


    let id_docente = this.state.usuario_session.id_docente;
    GetData('asignaciones/gradosdocente/' + id_docente, this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
              
              let lasigna = responseJSON.grados.map(
                    (item, key) => <Card key={key} className="card">                      
                              <CardHeader className="cardheader"                
                                    avatar={
                                      <i className="material-icons">timeline</i>
                                    }
                                    title={`${item.nombre_grado} ${item.num_grupo}`}
                                  />
                                <CardContent>
                                <Fade in={this.state.loadingfade} >
                                <List component="nav">
                                          <Suspense fallback={<div>Cargando ...</div>}>
                                             <GradoMaterias id_grados={item.id_grados} id_grupo={item.id_grupo} id_docente={id_docente}  />
                                         </Suspense>
                                </List>
                                </Fade>  
                                <hr />
                                 {/* <BTNObservador dgr={item.dirg} id_grados={item.id_grados} id_grupo={item.id_grupo} id_docente={id_docente} id_periodo={this.state.id_periodo} /> */}
                                 <Tooltip title="Listado de Alumnos" aria-label="add">
                                    <IconButton color="primary" aria-label="upload picture" component={Link} to={`/home/listadoalumnos/${item.id_grados}/${item.id_grupo}/${id_docente}/${this.state.id_periodo}`} >
                                        <BallotIcon />
                                  </IconButton> 
                                 </Tooltip>
                   
                                 <BTNConsolidado dgr={item.dirg} id_grados={item.id_grados} id_grupo={item.id_grupo} id_docente={id_docente} />
                                 <Button type="button" variant="contained" 
                                            className="MargenBoton"
                                            style={{display: item.dirg === "1" ? '' : 'none' }}
                                            size="small"
                                            component={Link} to={`/home/consolidadoresumen/${item.id_grados}/${item.id_grupo}/`} 
                                            color="secondary">Cons Resumen</Button>

                                  
                              </CardContent>  
                            </Card>          
                    );
              
              this.setState({lista_grados: lasigna}, () =>{
                   // this.setState({loading: false});
                    
                    //this.timerHandle = setTimeout(() =>  this.setState({ loading: false }), 3500); 
                   
                      setTimeout(
                        function() {
                          this.setState({loading: false})
                        }
                        .bind(this),
                        2000
                    );

              });
              
          }else{
            console.log("Error al cargar las aginaciones");
          }
      })
  }  

  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  render() {
    
   // let checked = false;

    return (
      
      <div className="container">
            <br />
            <div className='justify-content-center mb-2'>
                      <Button type="button" variant="contained" 
                                            className="centrar"
                                            size="small"
                                            component={Link} to={`/home/calificacionesperiodos/`} 
                                            color="secondary">Consolidados Asignaturas</Button>
            </div>
            
            <div className="card-columns">
            <LoadingScreen
                    loading={this.state.loading}
                    bgColor='#f1f1f1'
                    spinnerColor='#9ee5f8'
                    textColor='#676767'
                    logoSrc='/images/LogoCnotasGr.png'
                    text='...'
                  > 
                  {this.state.lista_grados}
            </LoadingScreen>
            
            </div>           

            
      </div>
   

    );
  }

  componentDidMount() {
    this.setState({loadingfade:true})
    this.listar_grados();    
  }  
  

}

export default Grados;
