import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import {PostData} from '../../services/PostData';
import LinearProgress from '@material-ui/core/LinearProgress';

class InputNotaExamen extends Component {
    constructor(props){
        super(props);
        this.state = {
            index: props.index,
            id_matricula: props.id_matricula,
            nota_alumno: props.nota,
            id_grado: props.id_grado,
            id_grupo: props.id_grupo,
            id_asignatura: props.id_asignatura,
            guardado: false,
            editactivo: false,
            error: false,
            urimg: '/images/save_exito.png'
        }
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.guardar_nota = this.guardar_nota.bind(this);
        this.resetSave = this.resetSave.bind(this);
            
    }

    func_round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    handleKeyUp(e) {
        //console.log(e.key);
        
  
        if (e.key === "Enter"){
  
          
          if(isNaN(e.target.value)){
             console.log("No es un numero")
             return;
           }

         
           
           let notaing = parseFloat(e.target.value);
  
           if (notaing > this.state.max_nota ){
              console.log("La nota debe ser menor o igual a " + this.state.max_nota);
              return;
           }
          
            this.setState({id_matricula: e.target.id});
         
           
            this.guardar_nota(notaing);  
            
        }else{
             this.setState({guardado: false});
        }
    }

    guardar_nota(notaing){    

        if (!notaing){
            this.setState({error: true});
            return;
        }

        let datosenvio = {
            id_matricula : this.state.id_matricula,
            id_grado : this.state.id_grado,
            id_grupo : this.state.id_grupo,
            id_asignatura: this.state.id_asignatura,
            nota_alumno: notaing
        }

        this.setState({editactivo: true})

        PostData('examenfinal/guadarnota/', datosenvio).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){              
              this.props.tab_control(this.props.id_matricula);       
              this.setState({guardado: true, error: false, editactivo: false});    

           //   return true;
          }else{
              
              this.setState({guardado: false, error: true, editactivo: false});
              console.log("Error al guardar la nota");
             // return false
          }
      })    
  }

  resetSave(e){
    
    console.log("Recibiendo el foco");  
    this.setState({guardado: false});
      
  }

  convertirnumero(numero){
        if (numero !== ""){
            return parseFloat(numero).toFixed(1)
        }
  }
  

    render() {

        return (
            <>
            <TextField error={this.state.error} innerRef={(el) => { this[`ref${this.props.index}`] = el; }} 
                    inputProps={{
                        maxLength: 3,
                    }}            
                    name={this.props.id_matricula} 
                    id={this.props.id_matricula} 
                    autoFocus={false}
                    disabled={this.state.editactivo}
                    className="quitarpading stiloimput" 
                    defaultValue={this.convertirnumero(this.state.nota_alumno)}  
                    onBlur={this.resetSave}
                    onKeyUp={this.handleKeyUp} 
                              onChange={this.props.onChangeNota}
                              onFocus={this.props.handleFocus}
                            />
                    <img src={this.state.urimg}  style={{visibility: this.state.guardado ? 'visible' : 'hidden' }} alt="img" />               
                    <LinearProgress  name={`prog_${this.props.index}`} style={{visibility: this.state.editactivo ? 'visible' : 'hidden' }} />
            </>
        );
    }
    componentDidMount() {
      //  this.setState({open: false});  
  
      }  
}

export default InputNotaExamen;