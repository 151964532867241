//export const URL_SERVICIOS = "http://inscandelaria.com/api_res_prado/index.php/";
//export const URL_INFORMES = "http://inscandelaria.com/api_res_prado/printer/";

export const URL_SERVICIOS = "https://inscandelaria.com/api_res_smartin/index.php/";
export const URL_INFORMES = "https://inscandelaria.com/api_res_smartin/printer/";

//export const URL_SERVICIOS = "https://inscandelaria.com/api_res_cand/index.php/";
//export const URL_INFORMES = "https://inscandelaria.com/api_res_cand/printer/";

//export const URL_SERVICIOS = "http://pruebas.colesper.com/api_res/index.php/";
//export const URL_SERVICIOS = "http://127.0.0.1/cnotas/api_res/index.php/";
//export const URL_SERVICIOS = "http://127.0.0.1/api_candelaria/api_res/index.php/";
//export const URL_INFORMES = "http://127.0.0.1/api_candelaria/api_res/printer/";


