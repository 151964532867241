import React, { Component } from 'react';

import './CalificacionesIndicador.css';
import Button from '@material-ui/core/Button';
import {GetData} from '../../services/GetData';
import {PostData} from '../../services/PostData';

import {Link} from 'react-router-dom';
import {Usuario} from '../../services/Usuario';


class CalificacionesIndicador extends Component {
  



  constructor(props){
    super(props);
    this.state = {
        usuario_session: Usuario(),
        id_grado: props.match.params.idGrado,
        id_grupo: props.match.params.idGrupo,
        id_asignatura: props.match.params.idAsignatura,
        id_actividades: props.match.params.idActividad,
        id_indicador: props.match.params.idIndicador,
        nombre_asing: null,
        nom_grado: null,
        lista_alumnos: <tr><td></td></tr>,
        titulos_tabla: <tr><td></td></tr>,
        num_periodo: 0,        
        nota_alumno: 0,
        id_matricula: 0,
        list_notas: {},
        guardado: {},
        listaindex : {},

    }
    this.listar_alumnos = this.listar_alumnos.bind(this);    
    this.periodo_actual = this.periodo_actual.bind(this);
    this.func_ingresarNota = this.func_ingresarNota.bind(this);
    this.onChangeNota = this.onChangeNota.bind(this);
    this.guardar_nota = this.guardar_nota.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.progreso_guardado = this.progreso_guardado.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.tab_control = this.tab_control.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.promedio = this.promedio.bind(this);
    this.info_grado = this.info_grado.bind(this);
    this.info_asigantura = this.info_asigantura.bind(this);
  }


  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  onChangeNota(e){
      console.log(e.target);
      const list_notas = this.state.list_notas;
      list_notas[e.target.name] = e.target.value;
      this.setState({ list_notas }, () => {console.log(this.state.list_notas);});      
  }  

  info_asigantura(){
    let id_docente = this.state.usuario_session.id_docente;
    GetData('asignaturas/infoasignatura/' + this.state.id_asignatura + "/" + id_docente, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){

        let nombre_asing = responseJSON.asignatura;  

        this.setState({
              nombre_asing: nombre_asing
          });
      }else{
          this.setState({errorvalidardoc: true})
        
      }
    }); 

    
  }

  info_grado(){
    
    GetData('grados/infogrado/' + this.state.id_grado, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){

        let nom_grado = responseJSON.nom_grado;  
        this.setState({
              nom_grado: nom_grado
          });
      }
    });    
  } 

  func_ingresarNota(id_matricula, nota){
      const list_notas = this.state.list_notas;
      list_notas[id_matricula] = nota
      this.setState({ list_notas });
      return true;      
  } 

  handleFocus(event) {
    event.target.select();
  }

  handleKeyUp(e) {
      //console.log(e.key);
      
      if (e.key === "Enter"){
          //let id = 15;
          //this[`ref${id}`].current.handleFocus();
          
          this.setState({id_matricula: e.target.id});
          this.guardar_nota(e.target.id);         
      }
  }

  updateValue(e) {

      const list_notas = this.state.list_notas;
      list_notas[e.target.name] = e.target.value;
      
      this.setState({ list_notas });
  }  

  progreso_guardado(id_matricula, estado){
            const guardado = this.state.guardado;
            guardado[`prog_${id_matricula}`] = estado;
            this.setState({ guardado });
  }

  tab_control(id_matricula){
      console.log("Procesando matriculas");
      let listaindex = this.state.listaindex;

      Object.keys(listaindex).forEach(function(key) {
            let nums = parseInt(key) + 1;
            console.log(listaindex.length + " " + nums)  ;
        
              if (id_matricula === listaindex[key]){
                /** validar que no se pase el key  */
                let indice = listaindex[nums];

                document.getElementById(String(indice)).focus();
                return;
            }
       

                
      });
      return;
  }

  guardar_nota(id_matricula){    
        let notav = this.state.list_notas[id_matricula];
        let datosenvio = {
            id_matricula : id_matricula,
            id_actividades : this.state.id_actividades,
            nota_alumno: notav
        }
        
       // this.progreso_guardado(id_matricula, true);
      
       /*
       if (this.state.list_notas.length <= (key + 1)) {
         this.refs[indice].focus();
       } 
       */

       
       
        PostData('calificaciones/guadarnota/', datosenvio).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){              
              console.log("nota guardada ");
            //  this.progreso_guardado(id_matricula, false);
              this.tab_control(id_matricula);
              return true;
          }else{
            console.log("Error al guardar la nota");
              return false
          }
      })    
  }

  periodo_actual(){
      GetData('periodos/actual/', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
          let periodo = responseJSON.periodo;  
          this.setState({num_periodo: periodo});
        }
      });
     
  }

  focusInput = (id) => this[`ref${id}`].current.focus();

  formato_numero(numero, colname){
      if (colname !== "nombres_apellidos_al" && colname !== "id_matricula"){
          if (numero !== null)
            return parseFloat(numero).toFixed(1);
          else   
            return parseFloat(0).toFixed(1);
      }else
          return numero;
      
  }

  promedio(notas, columas){
      
      let suma = 0;
      let numnotas = 0;
      let indice = 0;
      Object.keys(notas).forEach(function(key) {
          
          if (indice > 1){
            
            if (notas[key] !== null){
                suma += parseFloat(notas[key]);
                numnotas++;
            }    
          }
        
        indice++;
        
      });
      
      let promedio = 0
      if (suma > 0)
          promedio = (suma / numnotas);
      else
          promedio = 0;

      return parseFloat(promedio).toFixed(1);
      
  }

 listar_alumnos(){

    GetData('actividades/listaalumnosporlogro/' + this.state.id_indicador + "/" + this.state.id_grado + "/" + this.state.id_grupo, this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
 

              // se contruye las columnas de con los nombres de los campos para poder armar la tabla
              let objetos = Object.keys(responseJSON.alumnos[0]);
              let columas = [];
              let num_notas = 1;
              let sum_camp_nota = 100 - (objetos.length - 2) * 10;
              Object.keys(objetos).forEach(function(key) {
                  let nombrecampo = "";
                  let anchocol = "";
                  switch (key){
                    case '0':
                        nombrecampo = "Código";
                        anchocol = "5%";
                        break;
                    case '1':
                        nombrecampo = "Nombre del Alumno";
                        anchocol = sum_camp_nota + "%";
                        break;
                    default:
                        nombrecampo = "Nota " + num_notas;
                        anchocol = "8%";
                        num_notas++;
                        break;
                  }

                    let campos = { header: nombrecampo, name: objetos[key], size: anchocol };
                    columas.push(campos);

              });
              
         

              let colheader =  objetos.length -1;                         
              const renderData = (data, cols) =>
                  data.map((row, key) =>{ return [
                        <tr key={row.id_matricula}>
                        {cols.map(col =>
                          <td key={col.name}>{this.formato_numero(row[col.name], col.name)}</td>
                        )}
                        <td key={colheader+1}>{this.promedio(data[key], columas)}</td>
                    </tr>
                  ]
                  }

              );



              let listaindi = renderData(responseJSON.alumnos, columas);

              let campos = { header: "Prom", name: "promedio", size: "8%" };
              columas.push(campos);                   

              let titulostabla = columas.map(
                (item, key) =><th key={key} width={item.size}>{item.header}</th>);

              this.setState({ lista_alumnos : listaindi, titulostabla: titulostabla })
             
          }else{
            console.log("Error al cargar las aginaciones");
          }
      })
    }

  render() {
   
    
    return (
      
    <div className="container" >
        <br />

        <div className="card col-lg-12 col-md-12" >
          <div className="card-header">
                <h5>Ingreso de calificaciones, {this.state.nombre_asing} Grado: {this.state.nom_grado} - {this.state.id_grupo}, Período:  {this.state.num_periodo} </h5>
          </div>
          <div className="card-body">
            <h6 className="card-title">Lista de Alumnos</h6>     
            
          
            <table className="table table-bordered table-hover table-sm">
              <thead>
                <tr className="table-primary">
                  {this.state.titulostabla}
                </tr>
              </thead>
              <tbody>
                {this.state.lista_alumnos}
              </tbody>
            </table>

            <Button type="button" variant="contained" component={Link} to={`/home/actividades/${this.state.id_indicador}/${this.state.id_grado}/${this.state.id_grupo}/${this.state.id_asignatura}`} color="primary"> Regresar </Button>

          </div>
        </div>

    </div>
   

    );
  }

  componentDidUpdate(prevProps, prevState) {
       // this[`ref${5}`].handleFocus();
  }
  componentDidMount() {
    this.info_grado();
    this.info_asigantura();
    this.periodo_actual();
    this.listar_alumnos();    
  }    
}

export default CalificacionesIndicador;
