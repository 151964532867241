import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import {PostData} from '../../services/PostData';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Tooltip } from '@material-ui/core';


class InputNotaLetra extends Component {
    constructor(props){
        super(props);
        this.state = {
            index: props.index,
            notaalumno: props.nota,
            guardado: false,
            editactivo: false,
            error: false,
            max_nota: 5,
            urimg: '/images/save_exito.png'
        }
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.guardar_nota = this.guardar_nota.bind(this);
        this.deleteNota = this.deleteNota.bind(this);
        this.resetSave = this.resetSave.bind(this);
            
    }

    func_round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    handleKeyUp(e) {
        console.log(e.key);
        
  
        if (e.key === "Enter"){
  
          

         
           
           let notaing = e.target.value;
  
           if (notaing !== "A" && notaing !== "S" && notaing !== "D" && notaing !== "E"){
              console.log("La nota no es válida debe ser A, D o E ");
              this.props.error_nota_mayor();
              this.setState({error: true});
              return;
           }
          
            this.setState({id_matricula: e.target.id});
         
           
            this.guardar_nota(notaing);  
            
        }else{
             this.setState({guardado: false});
        }
    }

    guardar_nota(notaing){    
        let datosenvio = {
            id_matricula : this.props.id_matricula,
            id_indicador : this.props.id_indicador,
            id_asignatura: this.props.id_asignatura,
            id_grado : this.props.id_grado,
            id_grupo : this.props.id_grupo,
            id_periodo: this.props.id_periodo,
            nota_alumno: notaing
        }      
       
        if (!notaing){
            this.setState({error: true});
            return;
        }

        this.setState({editactivo: true})

        PostData('calificaciones/guadarnota/', datosenvio).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){              
              this.props.tab_control(this.props.id_matricula);       
              this.setState({guardado: true, error: false, editactivo: false});    

           //   return true;
          }else{
              
              this.setState({guardado: false, error: true, editactivo: false});
              
             // return false
          }
      })    
  }

  deleteNota = () => {
    let datosenvio = {
        id_matricula : this.props.id_matricula,
        id_indicador : this.props.id_indicador,
        id_asignatura: this.props.id_asignatura,
        id_grado : this.props.id_grado,
        id_grupo : this.props.id_grupo,
        id_periodo: this.props.id_periodo,
    }    
    PostData('calificaciones/deletenota/', datosenvio).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){              
            console.log(responseJSON)
            //this.props.tab_control(this.props.id_matricula);       
            if (parseInt(responseJSON.proceso) === 1){
                console.log("Proceso bien el eliminar ", responseJSON.proceso )
                this.setState({guardado: false, error: false, notadelete: false, notaalumno: ''});    
                this.props.onChangeNotaDelete(this.props.id_matricula,'');
                document.getElementById(String(this.props.id_matricula)).value = "";
            }
                
            
         //   return true;
        }else{
            
            this.setState({guardado: false, error: true, notadelete: true});
            console.log("Error al guardar la nota");
           // return false
        }
    }) 
}

  resetSave(e){
    
    
    this.setState({guardado: false});
      
  }


    render() {

        return (
            <>
             <div className='flex-container'>
                <div>
                    <TextField error={this.state.error} innerRef={(el) => { this[`ref${this.props.index}`] = el; }} 
                        inputProps={{
                            maxLength: 1,
                            style: { textAlign: 'center' }
                        }}
                        name={this.props.id_matricula} 
                        id={this.props.id_matricula} 
                        autoFocus={false}
                        disabled={this.state.editactivo}
                        className="quitarpading stiloimput nota-letra" 
                        defaultValue={this.state.notaalumno}  
                        onBlur={this.resetSave}
                        
                        onKeyUp={this.handleKeyUp} 
                                onChange={this.props.onChangeNota}
                                onFocus={this.props.handleFocus}
                                />
                        <img src={this.state.urimg}  style={{visibility: this.state.guardado ? 'visible' : 'hidden' }} alt="img" />               
                        <LinearProgress  name={`prog_${this.props.index}`} style={{visibility: this.state.editactivo ? 'visible' : 'hidden' }} />
                    </div>
                    <div>
                        <Tooltip  title="Borrar Nota" aria-label="add">
                        <i className="material-icons ColorAzul" onClick={()=> this.deleteNota() }
                    style={{visibility: this.state.notaalumno != "" || this.state.guardado ? 'visible' : 'hidden' }}>
                                            delete_forever
                                            </i>
                        </Tooltip>
                    </div>  
                </div> 
            </>
        );
    }
    componentDidMount() {
      //  this.setState({open: false});  
  
      }  
}

export default InputNotaLetra;