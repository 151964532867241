import React, { Component, lazy, Suspense } from 'react';
import {Route} from 'react-router-dom';
import './Printer.css';
import {Usuario} from '../../services/Usuario'
import {Redirect} from 'react-router-dom';


//import Indicadores from '../Indicadores/Indicadores';

const InformeAlumno = lazy(() => import("../InformeAlumno/InformeAlumno"));
const InformeAlumnobc = lazy(() => import("../InformeAlumnobc/InformeAlumnobc"));

class Printer extends Component {

  constructor(props){
      
      super(props);
      this.state = {
          usuario_session: Usuario(),
          id_docente: 0,
          username:'',
          password:'',
          lista_grados: <div></div>,
          redirect: false,
          error_login: false
      }

  }

  cerrar_session = () => {
     sessionStorage.clear();
     this.setState({usuario_session: Usuario()});
  }

 

  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  render() {
    
    

    if (!this.state.usuario_session) {
      return (<Redirect to={'/login'} />)
    }

    return (
      
          

    <div >
                  
          <Suspense fallback={<div className="loading"></div>}>
                <Route path='/printer/informealumno/:idMatricula/:idGrado/:idGrupo/:idDocente/:Printer' component={props => <InformeAlumno {...props} />} exact />    
                <Route path='/printer/informealumnobc/:idMatricula/:idGrado/:idGrupo/:idDocente/:Printer' component={props => <InformeAlumnobc {...props} />} exact />    
                            
          </Suspense>          

    </div>
   

    );
  }

  componentDidMount() {
    
    
  }  

}

export default Printer;
