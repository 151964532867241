import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import {GetData} from '../../services/GetData';


import {Link} from 'react-router-dom';

import LoadingScreen from 'react-loading-screen'
import {  FormControl, LinearProgress, MenuItem, Select  } from '@material-ui/core';


class Calificacionesfinalp extends Component {
  



  constructor(props){
    super(props);
    this.state = {
        id_grado: props.match.params.idGrado,
        id_grupo: props.match.params.idGrupo,
        id_asignatura: props.match.params.idAsignatura,
        id_actividades: props.match.params.idActividad,
        id_indicador: props.match.params.idIndicador,
        nom_grado: null,
        lista_alumnos: null,
        titulos_tabla: null,
        titulos_tabla2: null,
        num_periodo: 0,
        nota_alumno: 0,
        id_matricula: 0,
        list_notas: {},
        guardado: {},
        listaindex : {},
        ConfPorcenNotas: [20, 80],
        procentaje_ptl: 0, //% plataforma
        calificacionPTF: false,
        errorvalidarExF: false,
        loading: true,
        loading_bar: false

    }
    this.listar_alumnos = this.listar_alumnos.bind(this);    
    this.periodo_actual = this.periodo_actual.bind(this);
    this.info_grado = this.info_grado.bind(this)
    this.resumen_notas_nomal = this.resumen_notas_nomal.bind(this);
    this.listar_periodos = this.listar_periodos.bind(this);

  }



  periodo_actual(){
      GetData('periodos/actual/', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
          let periodo = responseJSON.periodo;  
          this.setState({num_periodo: periodo}, ()=> {
              this.listar_periodos()
          });
        }
      });
     
  }

  info_grado(){
    
    GetData('grados/infogrado/' + this.state.id_grado, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){

        let nom_grado = responseJSON.nom_grado;  
        this.setState({
              nom_grado: nom_grado
          });
      }
    });    
  }  

  

  
datos_columna(datos, cols){
    let retorno = [];
    Object.keys(datos).forEach(function(key) {
        let datocelda = datos[key];
        let nota = datocelda;
        let center = "";
        if (key > 1){
            nota = parseFloat(datocelda).toFixed(1);
            center = "center";
        }
        retorno.push(<td align={center} key={`td2${key}`}>{nota}</td>);
    })
    return retorno;
}

anchocolumna(key, total){
    

    let an = total - 3;
    let w = an * 10;
    let anca = 100 - w - 20; 

    if (key === 0)
        return "10%";

    if (key === 1)
        return anca + "%";

    if (key > 1 && key < total)
        return "10%";
    
    if (key === (total-1)){
        return "10%";
    }    
    
}

resumen_notas_nomal(columnas, datostabla){
    // se contruye las columnas de con los nombres de los campos para poder armar la tabla


    let objetos = Object.keys(columnas);
    let totalcol = objetos.length;


    let titulostabla = columnas.map(
        (item, key) =><th key={key} className={"text-center"}  width={this.anchocolumna(key,totalcol)}>{item}</th>);
  
                     
    const renderData = datostabla.map((alumno, key) =>
                <tr key={`tr1${key}`}>{
                    this.datos_columna(alumno, key)
                }</tr>);              



    let listaindi = renderData; //renderData(datostabla, colt_columtotal);                         

    this.setState({ lista_alumnos : listaindi, titulostabla: titulostabla })
}


listar_alumnos(){
    this.setState({loading_bar: true})
    GetData('calificaciones/tablanotasindicador/' + this.state.id_grado + "/" + this.state.id_grupo + "/" + this.state.id_asignatura + "/" + this.state.num_periodo, this.state).then ((result) =>{
          let responseJSON = result;
          //console.log(responseJSON);
          if (responseJSON.error === false){
             

            this.resumen_notas_nomal(responseJSON.titulotbl,responseJSON.tabla);
          
                      
            this.setState({loading: false, loading_bar: false})
             
          }else{
            console.log("Error al cargar las calificaciones");
          }
      })
    }


  cambiar_periodo = (e) => {
      this.setState({num_periodo: e.target.value}, () =>{
          this.listar_alumnos();
      });
      
  }

  listar_periodos(){
      let periodos_list = [];
  
      for (let i = 1; i <= this.state.num_periodo; i++)
          periodos_list.push(i);
  
      const lista = periodos_list.map((item, index) =><MenuItem key={index} value={item}>Periodo {item}</MenuItem>);
  
      this.setState({lista_periodos: lista})
  
  }      

  render() {
   
 
  

   
    return (
    
      
    <div className="container" >

      <LoadingScreen
                    loading={this.state.loading}
                    bgColor='#f1f1f1'
                    spinnerColor='#9ee5f8'
                    textColor='#676767'
                    logoSrc='/images/LogoCnotasGr.png'
                    text='...'
                  > 

        <div className="card" >
          <div className="card-header">
                <Button type="button" variant="outlined" component={Link} to={`/home/indicadores/${this.state.id_grado}/${this.state.id_grupo}/${this.state.id_asignatura}`} color="primary"> Regresar </Button>                
                <span className="h6 font_plataform fontRoboto LeftMargin">Calificaciones del 
                <strong> Período:</strong> 
                
                <FormControl className="ml-3">
                        
                        <Select
                          labelId="label-periodo"
                          id="id_periodo"
                          value= {this.state.num_periodo}
                          onChange={this.cambiar_periodo}
                        >                        
                            { this.state.lista_periodos }
                        </Select>
                      </FormControl>
                 
                <strong> Grado: </strong>{this.state.nom_grado} - {this.state.id_grupo} </span>
          </div>
          <div className="card-body">
          <LinearProgress style={{visibility: this.state.loading_bar ? 'visible' : 'hidden' }} />
       

            <h6 className="card-title">Lista de Alumnos</h6>     
            
            
            <table className="table table-bordered table-hover table-sm tablcal tblcalificaciones">
                <thead>
                <tr key={`trh${1}`} className="table-primary">{this.state.titulostabla}</tr>
                <tr key={`trh${2}`}>{this.state.titulos_tabla2}</tr>
                </thead>
                <tbody>{this.state.lista_alumnos}</tbody>
            </table>

            <Button type="button" variant="contained" component={Link} to={`/home/indicadores/${this.state.id_grado}/${this.state.id_grupo}/${this.state.id_asignatura}`} color="primary"> Regresar </Button>

          </div>
        </div>

        </LoadingScreen>

    </div>
   

    );
  }

  componentDidUpdate(prevProps, prevState) {
       // this[`ref${5}`].handleFocus();
  }
  componentDidMount() {      
    this.periodo_actual();
    this.info_grado();
    this.listar_alumnos(); 
  }    
}

export default Calificacionesfinalp;
