import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import {PostData} from '../../services/PostData';
import LinearProgress from '@material-ui/core/LinearProgress';


class InputNotaAsistencia extends Component {
    constructor(props){
        super(props);
        this.state = {
            index: props.index,
            notaalumno: props.nota,
            guardado: false,
            editactivo: false,
            error: false,
            max_nota: 5,
            urimg: '/images/save_exito.png'
        }
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.guardar_nota = this.guardar_nota.bind(this);
        this.resetSave = this.resetSave.bind(this);
            
    }

    handleKeyUp(e) {
        console.log(e.key);
        
  
        if (e.key === "Enter"){
  
          

         
           
           let notaing = e.target.value;
  
           if (isNaN(notaing)){
              console.log("La nota no es válida debe ser A, D o E ");
              this.props.error_nota_no_valida();
              this.setState({error: true});
              return;
           }
          
            this.setState({id_matricula: e.target.id});
         
           
            this.guardar_nota(notaing);  
            
        }else{
             this.setState({guardado: false});
        }
    }

    guardar_nota(notaing){    
        let datosenvio = {
            id_matricula : this.props.id_matricula,
            id_grado: this.props.id_grado,
            id_grupo: this.props.id_grupo,
            id_asignatura: this.props.id_asignatura, 
            id_periodo: this.props.id_periodo, 
            asistencia: notaing
        }      
       
        if (!notaing){
            this.setState({error: true});
            return;
        }

        this.setState({editactivo: true})

        PostData('calificaciones/guadarasistencia/', datosenvio).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){              
              this.props.tab_control(this.props.id_matricula);       
              this.setState({guardado: true, error: false, editactivo: false});    

           //   return true;
          }else{
              
              this.setState({guardado: false, error: true, editactivo: false});
              
             // return false
          }
      })    
  }

  resetSave(e){
    
    
    this.setState({guardado: false});
      
  }


    render() {

        return (
            <>
            <TextField error={this.state.error} innerRef={(el) => { this[`ref${this.props.index}`] = el; }} 
                    inputProps={{
                        maxLength: 2,
                        style: { textAlign: 'center' }
                    }}
                    name={this.props.id_matricula} 
                    id={this.props.id_matricula} 
                    autoFocus={false}
                    disabled={this.state.editactivo}
                    className="quitarpading stiloimput" 
                    defaultValue={this.state.notaalumno}  
                    onBlur={this.resetSave}
                    
                    onKeyUp={this.handleKeyUp} 
                              onChange={this.props.onChangeNota}
                              onFocus={this.props.handleFocus}
                            />
                    <img src={this.state.urimg}  style={{visibility: this.state.guardado ? 'visible' : 'hidden' }} alt="img" />               
                    <LinearProgress  name={`prog_${this.props.index}`} style={{visibility: this.state.editactivo ? 'visible' : 'hidden' }} />
            </>
        );
    }
    componentDidMount() {
      //  this.setState({open: false});  
  
      }  
}

export default InputNotaAsistencia;