import React, { Component } from 'react';
import {PostData} from '../../services/PostData'
import './CambioPassword.css';
import {Redirect} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Usuario} from '../../services/Usuario'

class CambioPassword extends Component {

  constructor(props){
      super(props);
      
      let usuariosession = Usuario();
      let id_usuario = null;
      let token = null;
      if (usuariosession){
          id_usuario  = usuariosession.id_usuario;
          token = usuariosession.token;
      }
          

      this.state = {
          id_usuario: id_usuario,
          password:'',
          password_r:'',
          token: token,
          redirect: false,
          error_login: false
      }
      this.cambiarpass = this.cambiarpass.bind(this);
      this.onChange = this.onChange.bind(this);
  }

  cambiarpass(event){
    event.preventDefault();
    if (this.state.password && this.state.password_r){
        if (this.state.password !== this.state.password_r){
            alert("Las contraseñas no coinciden verifique");
            return;
        }
    
        PostData('login/cambiarpassword', this.state).then ((result) =>{
          let responseJSON = result;


          if (responseJSON.error === false){

              this.setState({redirect: true});
               
          }else{
              console.log("Error al cambiar el password");                           
        }
      })
      
    }

  }

  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  render() {
    
    let message_error = <div></div>;

    if (this.state.error_login)
        message_error =  <span className="error-login text-danger"> * <strong>Error</strong> - Datos de ingreso no válidos, por favor verifique</span>

        
    if (this.state.redirect){
       return (<Redirect to={'/home'} />)
    }
    
    if (!this.state.id_usuario){
      return (<Redirect to={'/login'} />)
    }

    const styles = theme => ({
      main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
      },
      avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
      },
      submit: {
        marginTop: theme.spacing.unit * 3,
      },
    });

    return (


      <div className="main">
            <CssBaseline />
                  <Paper className="paper">
                      
                  <Avatar className="avatar">
                    <i className="material-icons">
                          info
                      </i>
                    </Avatar>
                   
                    <Typography component="h1" variant="h5">
                      Cambio de Contraseña
                    </Typography>
                    {message_error}
                    <div>
                      <center>Es necesario que cambie su contraseña por seguridad.</center>
                    </div>
                    <form className="formulario"  onSubmit={this.cambiarpass}>
                      <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Nueva Contraseña</InputLabel>
                        <Input name="password" id="password" type="password" autoComplete="Contraseña" onChange={this.onChange} autoFocus />
                      </FormControl>
                      <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">Confirmar Contraseña</InputLabel>
                        <Input name="password_r" id="password_r" type="password" onChange={this.onChange} autoComplete="Confirmar Contraseña" />
                      </FormControl>

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={styles.submit}
                      >
                        Guardar
                      </Button>
                    </form>
                  </Paper>
    </div>
   

    );
  }
}

export default CambioPassword;
