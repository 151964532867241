import React from 'react';
import { Redirect } from "react-router-dom";
import { Usuario } from "./Usuario";

export function AuthPeriodo({ children }) {
  let usuario = Usuario();
  
  console.log("estado", usuario)    
  if (usuario.periodo_es === "0") {
        return (<Redirect to={'/home'} />)
  } else {
        return children;
  }
}
