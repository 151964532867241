import React, { Component } from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import {PostData} from '../../services/PostData';

class NuevaActividad extends Component {

  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);  
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.guardar_actividad = this.guardar_actividad.bind(this);
    let f = new Date();
    let mes = (f.getMonth() +1)

    let fechactual = f.getFullYear() + "-" + (String(mes).length === 1? '0' + mes: mes ) + "-" + f.getDate();

    this.state = {
      accion: props.accion,
      id_grado: props.idGrado,
      id_grupo: props.idGrupo,
      id_actividades: props.id_actividades,
      nombre_actividad: props.nombre_actividad,
      fechahora_act: props.fechahora_act ? props.fechahora_act : fechactual,
      id_indicador: props.id_indicador,  
      cod_indicador: props.cod_ind,
      des_indicador: props.desc_ind,
      estado_periodo: props.estadoperiodo,
      lista_indicadores: <tr><td></td></tr>,
      error_fecha: false,
      show: false,
    };
    
  }

  guardar_actividad(){
    if (this.state.nombre_actividad && this.state.fechahora_act){

      // let hoy = new Date();
      // let fechaActividad = new Date(this.state.fechahora_act);
      
      // if (hoy >= fechaActividad ) {
      //     console.log("Fecha no valida");
      //      this.setState({error_fecha: true});
      //      return;
      // }

      PostData('actividades/save', this.state).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){           
          this.props.listarindicador();
          this.handleClose();
        }else{
          console.log("Error No se pudo guardar");
      }

    })
  }


  } 

  onChange(e){    
    console.log(e.target.name);
    this.setState({
        [e.target.name]: e.target.value
      });
  }   

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  
  render() {

    let numeral = "#";

    let boton = <Button style={{display: this.state.estado_periodo ? '' : 'none' }} type="button"  onClick={this.handleShow} color="primary"> Nueva Actividad</Button>

    if (this.state.accion === "edit"){
        boton =  <a href={numeral} onClick={this.handleShow} title="Editar Actividad">
          <i class="material-icons  colorVerde">edit</i></a>   
    }

    let msgerrorfecha = "";
    if (this.state.error_fecha){
       msgerrorfecha = "* La fecha no puede ser menor a la fecha actual ";       
    }

    return (
      <>
        
        {boton}

        <Modal show={this.state.show} onHide={this.handleClose} >
          <Modal.Header closeButton>
            <Modal.Title>Crear Nueva Actividad</Modal.Title>
          </Modal.Header>
          <Modal.Body>            
         
                <Form >      
                   {msgerrorfecha} 
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Titulo Actividad</Form.Label>
                    <Form.Control type="text" placeholder="Titulo" name="nombre_actividad" value={this.state.nombre_actividad} onChange={this.onChange}  />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Fecha</Form.Label>
                    <Form.Control type="date" name="fechahora_act" onChange={this.onChange}  value={this.state.fechahora_act} />
                  </Form.Group>
                  
                </Form>       

          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={this.guardar_actividad}>
                    Guardar
                  </Button>            
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  componentDidMount() {
 
    
  }  
}

export default NuevaActividad;
