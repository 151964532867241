import React, { Component } from 'react';
import {GetData} from '../../services/GetData';
import {URL_INFORMES} from '../../services/UrlWebServis';

import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid, LinearProgress } from '@material-ui/core';
import { Usuario } from '../../services/Usuario';




class Consolidado extends Component {

  constructor(props){
    
      super(props);
      this.state = {
          id_grado: props.match.params.idGrado,      
          id_grupo: props.match.params.idGrupo,
          usuario_session: Usuario(),
          id_periodo: 0,
          nom_grado: null,
          nombre_alumno: null,
          promedio_gen: 0,
          lista_materias: null,
          lista_informe_alumno: null,
          lista_periodos: null,
          loading: true
      }    
      
      
      this.fun_info_alumno = this.fun_info_alumno.bind(this);
      this.info_grado = this.info_grado.bind(this);
      this.lista_alumnos_grado = this.lista_alumnos_grado.bind(this);
      this.periodo_actual = this.periodo_actual.bind(this);
      this.celdas_tabla = this.celdas_tabla.bind(this);
      this.formato_celda = this.formato_celda.bind(this);
      this.listar_periodos = this.listar_periodos.bind(this);

  }

   info_grado(){
    
    GetData('grados/infogrado/' + this.state.id_grado, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){

        let nom_grado = responseJSON.nom_grado;  
        this.setState({
              nom_grado: nom_grado
          });
      }
    });    
  } 

  fun_info_alumno(){

    GetData('matriculas/datosmatricula/' + this.state.id_matricula, this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){
         let datos_alumno = responseJSON.matricula[0];  
       
        this.setState({
          nombre_alumno : datos_alumno.nombres_apellidos_al
          }, () => {
               // this.lista_materias();
          });
      }
    });  
  }

  periodo_actual(){
    GetData('periodos/actual/', this.state).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){
        let periodo = responseJSON.periodo;  
        this.setState({id_periodo: periodo}, () => {
            this.listar_periodos();
            this.lista_alumnos_grado();
            
        });
      }
    });
   
}

  listado_materias(materias){
      return  materias.map(
             (item, index) =><><th width="5%" className="text-center fontTabla">{index}</th></>
             );
  }

  formato_celda(key, valor){
      
    if (parseInt(this.state.id_grado) >= 5){
        if (key > 1){
            if (valor === "A" || valor === "D" || valor === "E" || valor === "S")
                return valor;
            else
                return parseFloat(valor).toFixed(1);
        }else
            return valor;    
    }else
        return valor;    
}  

 

  celdas_tabla(fila){
    let retorno = [];
    
    Object.keys(fila).forEach(function(key) {
      let caption = fila[key];
          
      let clase = "";

      if (key !== "1"){
          clase = "text-center fontTabla ";
      }else{
          clase = "text-left fontTabla ";
      }

      if (parseInt(this.state.id_grado) >= 5){
          if (parseInt(key) > 1){
             if (parseFloat(caption) < 3)
                clase += "nota_perdida";
          }
      }
      
    /*  if (parseInt(key) > 1 && ){
          clase += "nota_perdida";
      }*/

    retorno.push(<td key={`td2${key}`} className={clase}>{this.formato_celda(key, caption)}</td>);

    }.bind(this))
    
    
    return  retorno;
  }
  listar_alumnos_notas(datos){
    return  datos.map(
    (item, index) =><><tr key={item.id_alumnos}>{this.celdas_tabla(item)}</tr></>
      );
  }

  lista_alumnos_grado(){


        
        let id_grado = this.state.id_grado;
        let id_grupo = this.state.id_grupo; 
        const token = this.state.usuario_session.token;
        const id_usuario = this.state.usuario_session.id_usuario;
        
        this.setState({loading: true});

        GetData('informespdf/consolidadperiodo/' + id_grado + '/' + id_grupo + '/' + this.state.id_periodo + "/"+id_usuario+"/"+token, this.state).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
            
            let datos = responseJSON.datos;
            let materias = responseJSON.materias;
            let columnaPro = <th width="5%" className="fontTabla">Prom</th>

            if (this.state.id_grado < 5)
                columnaPro = "";            
            
            let header = <table className="content-table">
                <thead>
                  <tr className="">
                    <th width="5%" className="fontTabla">Código</th>
                    <th width="45%" className="fontTabla">Nombre del estudiante</th>
                     {this.listado_materias(materias)}
                     {columnaPro}
                  </tr>
                </thead>
                <tbody>
                  {this.listar_alumnos_notas(datos)}
                </tbody>
              </table>

          let retorno = [];
              Object.keys(materias).forEach(function(key) {
              retorno.push(<><span className="materias fontTabla" key={`span${key}`}>({key}) {materias[key]}</span> </>
                    );
              });
              
           
           
            this.setState({lista_alumnos_grado: header, lista_materias: retorno, loading: false})
          }
           
        });

    
  }

  listar_periodos(){
    let periodos_list = [];

    for (let i = 1; i <= this.state.id_periodo; i++)
        periodos_list.push(i);

    const lista = periodos_list.map((item, index) =><MenuItem key={index} value={item}>Periodo {item}</MenuItem>);

    this.setState({lista_periodos: lista})

  }

  cambiar_periodo = (e) => {
      this.setState({id_periodo: e.target.value}, () =>{
          this.lista_alumnos_grado();
      });
      
  }
 
  render() {
    let numeral = "#";
    let botonreturn = null;
    if (this.state.impresion === "1"){
        botonreturn = <Button type="button" variant="contained" className="MargenBoton" component={Link} to={`/home/listadoalumnos/${this.state.id_grado}/${this.state.id_grupo}/${this.state.id_docente}`} color="primary"> Regresar </Button>
    }

    return (
      
    <div className="container">
         <div className="card" >
            <div className="card-header">
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      <Grid item xs={2}>
                          <Button type="button" variant="outlined" component={Link} to={`/home/`} color="primary"> Regresar </Button>        
                      </Grid>
                     
                      <Grid item xs={2}>
                      <strong >Grado:</strong> {this.state.nom_grado} - 0{this.state.id_grupo} 
                      </Grid>
                      <Grid item xs={3}>
                            <strong  className="ml-3">Período:</strong>  
                            <FormControl className="ml-3">
                              
                              <Select
                                labelId="label-periodo"
                                id="id_periodo"
                                value= {this.state.id_periodo}
                                onChange={this.cambiar_periodo}
                              >                        
                                  { this.state.lista_periodos }
                              </Select>
                            </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                   

                      <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
            

                          <div className="btn-group" role="group">
                            <button id="btnGroupDrop1" type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                              Consolidados
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                              <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Por Período</a></li>
                              <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados_nive.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Por Período y Nivelación</a></li>
                              <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados_puesto.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Por Período y Puesto</a></li>
                              <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados_sum.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Período Acumulado Total</a></li>
                              <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados_prom.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Promedio por Período</a></li>
                              <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados_excelpro.php?grado=${this.state.id_grado}&grupo=${this.state.id_grupo}&periodo=${this.state.id_periodo}&doc=${this.state.usuario_session.id_docente}&t=${this.state.usuario_session.token}`}>Excel Calculo Promedio</a></li>
                            </ul>
                          </div>
                        </div>
                      
                      </Grid>
                </Grid>       
           
            </div>
            <LinearProgress style={{visibility: this.state.loading ? 'visible' : 'hidden' }} />
            <div className="card-body">
                      <div className="lista_materias">
                          {this.state.lista_materias}
                      </div>
                      {this.state.lista_alumnos_grado}       
            </div>
                     
                {botonreturn}
          </div>    
    </div>
   

    );
  }

  componentDidMount() {
    this.info_grado();
    this.periodo_actual();
    
    

  }    

}

export default Consolidado;
